import { SIDE_NAV_LIGHT, DIR_LTR, NAV_TYPE_TOP } from 'constants/ConstantTheme'

export const APP_NAME = 'Agotani.com'
export const APP_PREFIX_PATH = ''
export const AUTH_PREFIX_PATH = '/authentication'
export const REDIRECT_URL_KEY = 'redirect'
export const AUTHENTICATED_ENTRY = `${APP_PREFIX_PATH}/dashboard/summary`
export const UNAUTHENTICATED_ENTRY = '/signin'

export const THEME_CONFIG = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: 'en',
  navType: NAV_TYPE_TOP,
  topNavColor: '#29A56C',
  headerNavColor: '',
  mobileNav: false,
  currentTheme: 'light',
  direction: DIR_LTR,
  blankLayout: false,
}
