import { combineReducers } from 'redux'
import theme from './slices/themeSlice'
import auth from './slices/authSlice'

const rootReducers = combineReducers({
  theme: theme,
  auth: auth,
})

export default rootReducers
