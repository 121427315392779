import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/ConfigApp'

export const publicRoutes = [
  {
    key: 'signin',
    path: `${AUTH_PREFIX_PATH}/signin`,
    component: React.lazy(() => import('presentation/pages/authentication/signin/SigninIndex')),
  },
  {
    key: 'page-not-found',
    path: `${AUTH_PREFIX_PATH}/page-not-found`,
    component: React.lazy(() =>
      import('presentation/pages/authentication/page-not-found/PageNotFoundIndex'),
    ),
  },
]

export const protectedRoutes = [
  {
    key: 'dashboard.summary',
    path: `${APP_PREFIX_PATH}/dashboard/summary`,
    component: React.lazy(() => import('presentation/pages/dashboard/summary/SummaryIndex')),
  },
  {
    key: 'dashboard.sales',
    path: `${APP_PREFIX_PATH}/dashboard/sales`,
    component: React.lazy(() => import('presentation/pages/dashboard/sales/SalesIndex')),
  },
  {
    key: 'master.manage',
    path: `${APP_PREFIX_PATH}/administrator/master/manage`,
    component: React.lazy(() => import('presentation/pages/administrator/master/MasterIndex')),
  },
  {
    key: 'master.bank.manage',
    path: `${APP_PREFIX_PATH}/administrator/master/bank/manage`,
    component: React.lazy(() => import('presentation/pages/administrator/master/bank/BankIndex')),
  },
  {
    key: 'master.bank.create',
    path: `${APP_PREFIX_PATH}/administrator/master/bank/create`,
    component: React.lazy(() => import('presentation/pages/administrator/master/bank/BankCreate')),
  },
  {
    key: 'master.bank.update',
    path: `${APP_PREFIX_PATH}/administrator/master/bank/update`,
    component: React.lazy(() => import('presentation/pages/administrator/master/bank/BankUpdate')),
  },
  {
    key: 'master.bank.view',
    path: `${APP_PREFIX_PATH}/administrator/master/bank/view`,
    component: React.lazy(() => import('presentation/pages/administrator/master/bank/BankView')),
  },
]
